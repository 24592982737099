<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="question"></el-input>
<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>-->
<!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>-->
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <!-- <el-upload action="http://localhost:9090/topic/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button> -->
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="topicId" label="ID" width="80" sortable></el-table-column>
      <el-table-column prop="paper" label="归属试卷" width="200" sortable>
        <template slot-scope="scope">
          <el-tag v-for="p in scope.row.paper" :key="p">{{ p.paperName }}&nbsp;&nbsp;</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="question" label="题目" width="200" sortable>
        <template slot-scope="scope">
          <span  v-html="scope.row.question"> </span>
        </template>
      </el-table-column>
      <el-table-column prop="topicType" label="类型" width="80" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.topicType===0">单选题</span>
          <span v-if="scope.row.topicType===1">多选题</span>
          <span v-if="scope.row.topicType===2">判断题</span>
          <span v-if="scope.row.topicType===3">填空题</span>
          <span v-if="scope.row.topicType===4">简答题</span>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="分数" width="80" sortable></el-table-column>
      <el-table-column prop="difficulty" label="难度" width="80" sortable></el-table-column>
      <el-table-column prop="correctAnswer" label="正确答案" sortable>
        <template slot-scope="scope">
          <span  v-html="scope.row.correctAnswer"> </span>
        </template>
      </el-table-column>
      <el-table-column prop="analysis" label="答案分析" sortable>
        <template slot-scope="scope">
          <span  v-html="scope.row.analysis"> </span>
        </template>
      </el-table-column>

      <el-table-column label="操作"  width="180" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.topicId)"
          >
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="类型">
          <el-select v-model="form.topicType" placeholder="请选择"
          @change = "handleChange"
          >
            <el-option
                v-for="item in [
                    {value: 0,label: '单选'},
                      {value: 1,label: '多选'},
                      {value: 2,label: '判断'},
                      {value: 3,label: '填空'},
                      {value: 4,label: '简答'}
                  ]"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model="form.required" autocomplete="off"></el-switch>
        </el-form-item>
        <el-form-item label="题目">
          <ckeditor  v-model="form.question" :config="editorConfig"></ckeditor>
        </el-form-item>

        <el-form-item label="内容">

          <!-- 单项选择题 -->
          <div class="userAnswer" v-if="form.topicType === 0">
            <div class="radios">
              <el-radio
                  v-for="(item, index) in form.choice"
                  :key="index"
                  v-model="form.correctAnswer"
                  :label="item"
              >
                <span class="topicNavIndex">{{ String.fromCharCode(65 + index) }}、</span>
                <div ><el-input @input="inputchange" v-model="form.choice[index]" type="textarea" autosize></el-input></div>
              </el-radio>
            </div>
          </div>

          <!-- 多项选择题 -->
          <div class="userAnswer" v-if="form.topicType === 1">
            <div class="checkbox">
              <el-checkbox-group v-model="form.correctAnswer" @change="ckchange" >
                <el-checkbox
                    v-for="(item, index) in form.choice"
                    :label="item"
                    :key="index">
                  <span class="topicNavIndex">{{ String.fromCharCode(65 + index) }}、</span >
                  <span><el-input @input="inputchange" v-model="form.choice[index]" type="textarea" autosize></el-input></span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <!-- 判断题 -->
          <div class="userAnswer" v-if="form.topicType === 2">
            <div class="TrueOrFalse">
              <el-radio v-model="form.correctAnswer" label="true">正确</el-radio>
              <el-radio v-model="form.correctAnswer" label="false">错误</el-radio>
            </div>
          </div>

          <!-- 填空题 -->
          <div class="userAnswer" v-if="form.topicType == 3">
            <el-button size="mini" @click="addFillSymbol()"
            >插入填空符</el-button>
            <span style="font-size: 12px"
            >(三个下划线为一个填空符)</span>

            <div class="fillInBlank">
              <div
                  v-for="(q, index) in form.question.split('___')"
                  :key="index"
              >
                <el-input
                    type="textarea"
                    autosize
                    placeholder="请回答"
                    v-if="index !=form.question.split('___').length - 1"
                    v-model="form.correctAnswer[index]"
                >
                </el-input>
              </div>
            </div>
          </div>

          <!-- 简答题 -->
          <div class="userAnswer" v-if="form.topicType === 4">
            <div class="text">
              <strong>&nbsp;&nbsp;关键字</strong>
              <div v-for="(q, index) in form.correctAnswer" :key="index">
                <el-input
                    type="textarea"
                    autosize
                    @input="inputchange"
                    placeholder="请输入关键字"
                    v-model="form.correctAnswer[index]"
                >
                </el-input>
              </div>
              <el-button
                  class="addRadios"
                  size="mini"
                  icon="el-icon-plus"
                  @click="addKeyWord()"
              >添加关键字</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import "@/assets/less/testPaper.less";
import CKEditor from "ckeditor4-vue";
export default {
  name: "Topic",
  components: {
    // Use the <ckeditor> component in this view.
    // ckeditor: CKEditor.component,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      question: "",
      form: {
        question: "这是我新建的题目",
        choice: ["选项1", "选项2", "选项3", "选项4"],
        correctAnswer: [],
        analysis: "这是答案分析",
        difficulty: "中等",
        score: 10,
        required: true
      },
      editMode: false,  // 编辑状态标志位
      editedQuestion: '',
      editorData:'',//符文本编辑器的内容
      //编译器的网址
      editorUrl:'http://localhost:8080/ckeditor/ckeditor.js',
      editorConfig:{
        removeButtons:'Source,Templates,Save,Print,PasteText,Scayt,Language,ShowBlocks,About',
        // 工具栏组
        toolbarGroups:[
          { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
          { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
          { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
          { name: 'forms', groups: [ 'forms' ] },
          { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
          { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
          { name: 'links', groups: [ 'links' ] },
          { name: 'insert', groups: [ 'insert' ] },
          { name: 'styles', groups: [ 'styles' ] },
          { name: 'colors', groups: [ 'colors' ] },
          { name: 'tools', groups: [ 'tools' ] },
          { name: 'others', groups: [ 'others' ] },
          { name: 'about', groups: [ 'about' ] },
          {name:'math',groups:['MathType']},
        ],
        removePlugins: 'heading, link, list',
        allowedContent: true,
      },//富文本编辑器的配
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    handleChange(value){
      console.log(value)
      if (value===1||value===0) {
        if (!this.form.choice) {
          this.form.choice = ['', '', '', '']
        }
      }else if (value===4){
        this.form.correctAnswer = [];
      }

    },
    //按填空符(三个下划线)划分字符串
    fillSymbolStr () {
      return function (str) {
        let q = str.split("___");
        console.log(q)

        this.$forceUpdate();
        return q;
      };
    },
    //添加填空符
    addFillSymbol () {
      var str = this.form.question;
      if (str.lastIndexOf('</p>')!==-1){
          str = str.substring(0,str.lastIndexOf('</p>'))+'___</p>'
      }else {
        str = str+"___";
      }
      this.form.question = str ;
      this.$forceUpdate();
    },
    //添加关键字
    addKeyWord () {
      console.log(this.form)
      this.form.correctAnswer.push("");
      this.$forceUpdate();
    },
    inputchange(){
      this.$forceUpdate();
    },
    ckchange(v){
      this.$forceUpdate();
    },
    load() {
      this.request.get("/topic/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          question: this.question,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    save() {
      this.form.required = this.form.required?0:1

      //处理选择题选项
        var choice = "";
        if (this.form.choice) {
          this.form.choice.forEach((c) => {
            choice += c + "\n";
          });
        }
      this.form.choice = choice.slice(0, -1);
      this.form.required = this.form.required === true ? 1 : 0

      console.log(this.form)
      //处理正确答案
        if (!this.form.correctAnswer) {
          this.$message.warning("有题目未选答案");
          return
        }
        if (this.form.correctAnswer instanceof Array) {
          if (this.form.correctAnswer.length === 1 && this.form.correctAnswer[0] === '') {
            this.$message.warning("有题目未选答案");
            return
          }
          var correctAnswer = "";
          this.form.correctAnswer.forEach((c) => {
            correctAnswer += c + "\n";
          });
          this.form.correctAnswer = correctAnswer.slice(0, -1);
        }
      this.request.post("/topic", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {
        question: "这是我新建的题目",
        choice: ["选项1", "选项2", "选项3", "选项4"],
        correctAnswer: [],
        analysis: "这是答案分析",
        difficulty: "中等",
        score: 10,
        required: true
      }

      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      if (this.form .topicType == 4 || this.form .topicType == 3 || this.form .topicType == 1) {
        if (this.form .correctAnswer!=null&&this.form .correctAnswer!='undefined') {
          this.form .correctAnswer = this.form .correctAnswer.split(/[\n]/g);
        }
      }
      //按换行符分割字符串
      if (this.form .choice) {
        this.form .choice = this.form .choice.split(/[\n]/g);
      }
      this.form .required = this.form .required === 1 ? true : false;
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
    del(id) {
      this.request.delete("/topic/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.topicId)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/topic/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.question = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open("http://localhost:9090/topic/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
